<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span class="option_txt">작업일자</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
        <div>
          <span class="option_txt">제품명</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="제품명 검색"
              :value="search_value"
              @input="typing"
            />

            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <span class="option_txt">비고</span>
          <div class="input_search">
            <input
              type="text"
              placeholder="비고내용 검색"
              :value="comment_value"
              @input="typingComment"
            />

            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ targetQuantity.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>작업일자</th>
              <th>생산제품</th>
              <th>목표수량</th>
              <th>작업지시자</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(target, index) in targetQuantity"
              :key="target.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ target.work_date }}</td>
              <td>
                {{ getProductName(target.product_id) }}
              </td>
              <td class="text_right">{{ $makeComma(target.quantity) }}</td>
              <td>{{ getUserName(target.user_id) }}</td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal()">
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>
          세부정보
        </h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label>작업일자</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              v-model="managementData.work_date"
              :disabled="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>생산제품</label>
            <div class="input_search">
              <input
                type="text"
                placeholder="제품 검색"
                :disabled="!managementMode || selectedIndex == -1"
                :value="
                  managementData.product_id == null
                    ? product_search_text
                    : getProductName(managementData.product_id)
                "
                readonly
              />
              <button>
                <i
                  class="fa fa-search"
                  @click="showProductSearch = true"
                  v-if="selectedIndex != -1 && managementMode"
                ></i>
              </button>
            </div>
          </div>
          <div class="input_text">
            <label>목표수량</label>
            <input
              type="text"
              placeholder="내용을 입력하세요"
              inputmode="decimal"
              :value="$makeComma(managementData.quantity)"
              @input="$inputNumberInt($event, managementData, 'quantity')"
              :disabled="!managementMode || selectedIndex == -1"
            />
          </div>
          <div class="input_text">
            <label>작업지시자</label>
            <input
              type="text"
              placeholder="내용을 입력하세요"
              :disabled="!managementMode || selectedIndex == -1"
              readonly
              :value="getUserName(managementData.user_id)"
            />
          </div>
          <div class="input_text">
            <label>비고</label>
            <textarea
              v-model="managementData.comment"
              :disabled="!managementMode || selectedIndex == -1"
            ></textarea>
          </div>
          <div class="btn_wrap">
            <button
              class="btn_sub2"
              type="button"
              v-show="managementMode && selectedIndex != -1"
              :disabled="checkValid"
              @click="submitForm()"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteQuantity($event)"
    ></two-button-modal>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :pre_search_value="product_search_text"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import { yyyymmdd } from '@/utils/func';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
// import { yyyymmdd } from '@/utils/func';
export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, FavoriteMixin],
  components: {
    TwoButtonModal,
    ResourceSearch,
  },
  data() {
    return {
      showProductSearch: false,
      product_search_text: '',

      comment_value: '',
      search_value: '',

      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedIndex: 'getSelectedIndexFromQuantity',
      managementMode: 'getManagementModeFromTargetQuantityPage',
      managementData: 'getManagementDataFromQuantity',
      products: 'getProduct',
      start_date: 'getStartDateFromQuantity',
      end_date: 'getEndDateFromQuantity',
      target_quantity: 'getTargetQuantity',
      users: 'getUsersTempFromUserPage',
    }),
    targetQuantity() {
      const Hangul = require('hangul-js');
      if (this.target_quantity.length < 1) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.target_quantity)
          .filter(
            x =>
              this.findInfoFromId(this.products, x.product_id).name.includes(
                this.search_value,
              ) ||
              Hangul.d(
                this.findInfoFromId(this.products, x.product_id).name,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.search_value),
          )
          .filter(
            x =>
              x.comment.includes(this.comment_value) ||
              Hangul.d(x.comment, true)
                .map(x => x[0])
                .join('')
                .includes(this.comment_value),
          )
          .sort((a, b) => new Date(b.work_date) - new Date(a.work_date));
      }
    },
    checkValid() {
      const productId = this.managementData.product_id;
      const quantity = this.managementData.quantity;

      const chk = [
        { check: productId, data: null },
        { check: quantity, data: 0 },
      ];
      for (let i = 0; i < chk.length; i++) {
        if (chk[i].check == chk[i].data) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    typing(e) {
      this.search_value = e.target.value;
      this.SelectRow(-1);
    },
    typingComment(e) {
      this.comment_value = e.target.value;
      this.SelectRow(-1);
    },
    SelectRow(index) {
      this.$store.commit('setSelectedIndexToQuantity', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToQuantity', {
          id: -1,
          work_date: '',
          product_id: null,
          quantity: 0,
          user_id: null,
          comment: '',
        });
      } else {
        this.$store.commit(
          'setManagementDataToQuantity',
          this.lodash.clonedeep(this.targetQuantity[index]),
        );
      }
    },
    async deleteQuantity() {
      this.showSpinner();
      await this.$store
        .dispatch(
          'DELETE_TARGET_QUANTITY',
          this.target_quantity[this.selectedIndex].id,
        )
        .then(() => {
          this.$store.commit('setSelectedIndexToQuantity', -1);
          this.$store.commit('setManagementDataToQuantity', {
            id: -1,
            work_date: '',
            product_id: null,
            quantity: 0,
            user_id: null,
            comment: '',
          });
          this.FETCH_TARGET_QUANTITY();
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '삭제 중 오류 발생');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },
    async submitForm() {
      const payload = this.lodash.clonedeep(this.managementData);
      payload.quantity = this.$makeNumber(payload.quantity);
      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_TARGET_QUANTITY', payload)
        .then(() => {
          this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
          this.$store.commit('setManagementDataToQuantity', {
            id: -1,
            work_date: null,
            product_id: null,
            quantity: 0,
            user_id: null,
            comment: '',
          });
          this.FETCH_TARGET_QUANTITY();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('수정 중 오류', '수정 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    selectProduct(arg) {
      console.log(arg);
      this.managementData.product_id = arg.id;
      this.showProductSearch = false;
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToQuantity', e.target.value);
          this.SelectRow(-1);
          await this.FETCH_TARGET_QUANTITY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToQuantity', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToQuantity', e.target.value);
          this.SelectRow(-1);
          await this.FETCH_TARGET_QUANTITY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToQuantity', yyyymmdd(new Date()));
      }
    },
    async FETCH_TARGET_QUANTITY() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_TARGET_QUANTITY', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getProductName(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    getUserName(id) {
      const user = this.users.find(x => x.id == id);
      if (user != null || user != undefined) {
        return user.name;
      } else {
        return '';
      }
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      this.$store.commit('setEndDateToQuantity', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setStartDateToQuantity', yyyymmdd(lastMonth));
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '생산제품');
    }
    if (this.users.length < 1) {
      await this.FETCH('FETCH_USER_LIST', '작업지시자');
    }
    await this.FETCH_TARGET_QUANTITY();
  },
};
</script>

<style scoped></style>
