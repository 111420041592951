<template>
  <div class="article">
    <div class="aside_management_mode client_regist_mode">
      <div class="head">
        <div class="input_text">
          <label>목표 설정</label>
        </div>
      </div>

      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label>작업일자</label
              ><input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="newData.work_date"
              />
            </div>
            <div class="input_text">
              <label>생산제품</label>
              <div class="input_search">
                <input
                  type="text"
                  placeholder="생산제품 검색"
                  :value="
                    newData.product_id == null
                      ? product_search_text
                      : getProductName(newData.product_id)
                  "
                  @input="handleProductInput($event)"
                  :readonly="newData.product_id != null"
                />
                <button>
                  <i class="fa fa-search" @click="showProductSearch = true"></i>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>작업지시자</label
              ><input
                type="text"
                :value="users.find(x => x.account == userId).name"
                readonly
              />
              <!-- @input="newData.user_id = $event.target.value" -->
            </div>

            <div class="input_text">
              <label>목표수량</label
              ><input
                type="text"
                :value="$makeComma(newData.quantity)"
                inputmode="decimal"
                @input="$inputNumberInt($event, newData, 'quantity')"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>비고</label>
              <textarea v-model="newData.comment"></textarea>
            </div>
          </div>
          <div class="btn_wrap">
            <button
              class="btn_sub2"
              type="button"
              :disabled="checkValid"
              @click="submitForm()"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :pre_search_value="product_search_text"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';

export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin],
  components: {
    ResourceSearch,
  },
  data() {
    return {
      showProductSearch: false,
      product_search_text: '',
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      newData: 'getNewDataFromQuantity',
      users: 'getUsersTempFromUserPage',
      userId: 'getUserId',
    }),
    checkValid() {
      const productId = this.newData.product_id;
      const quantity = this.newData.quantity;

      const chk = [
        { check: productId, data: null },
        { check: quantity, data: 0 },
      ];
      for (let i = 0; i < chk.length; i++) {
        if (chk[i].check == chk[i].data) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    getProductName(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    handleProductInput(e) {
      this.product_search_text = e.target.value;
    },
    selectProduct(arg) {
      console.log(arg);
      this.newData.product_id = arg.id;
      this.showProductSearch = false;
    },
    async submitForm() {
      this.showSpinner();
      const payload = this.lodash.clonedeep(this.newData);
      payload.quantity = this.$makeNumber(payload.quantity);
      payload.user_id = this.users.find(x => x.account == this.userId).id;

      await this.$store
        .dispatch('INSERT_TARGET_QUANTITY', payload)
        .then(() => {
          this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
          this.$store.commit('setNewDataToQuantity', {
            work_date: yyyymmdd(new Date()),
            product_id: null,
            quantity: 0,
            user_id: null,
            comment: '',
          });
        })
        .catch(() => {
          this.openOneButtonModal('등록 실패', '등록에 실패하였습니다.');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },

  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '생산제품');
    }
    if (this.users.length < 1) {
      await this.FETCH('FETCH_USER_LIST', '작업지시자');
    }
  },
};
</script>

<style scoped></style>
